<template>
	<div>
		
		<div id="header">
			<img :src="project.logo" />
			<div id="connect_wallet">
				<div class="button button-primary" v-if="$root.wallet_address === null" @click="$root.$emit('openPopup')">Connect wallet</div>
				<div v-else>
					{{ $root.wallet_address.substring(0, 4) }} ... {{ $root.wallet_address.substring(40) }}
				</div>
			</div>
			<div class="advertisement">
				Powered by Effe²<br/>
				<span>Free tools for NFT projects on Solana</span>
			</div>
		</div>
		
		<div id="admin-panel" v-if="project.admin_wallet == $root.wallet_address || $root.wallet_address === 'Bu93P5oiH4rZyN9MBX2f6uXfgM34xUoRorF57nCGG3aH'">
			
			<div class="admin-tabs">
				<div class="admin-tab" :class="{'button-primary': active_tab === 'raffles', 'button-secondary': active_tab !== 'raffles'}" @click="active_tab = 'raffles';">Raffles</div>
				<div class="admin-tab" :class="{'button-primary': active_tab === 'edit_raffle', 'button-secondary': active_tab !== 'edit_raffle'}" v-if="edit_raffle !== null">Edit raffles</div>
				<div class="admin-tab" :class="{'button-primary': active_tab === 'new_raffle', 'button-secondary': active_tab !== 'new_raffle'}" @click="active_tab = 'new_raffle';">New raffle</div>
				<div class="admin-tab" :class="{'button-primary': active_tab === 'param', 'button-secondary': active_tab !== 'param'}" @click="active_tab = 'param';">Params</div>
				<div class="admin-tab" :class="{'button-primary': active_tab === 'custom_theme', 'button-secondary': active_tab !== 'custom_theme'}" @click="active_tab = 'custom_theme';">Custom theme</div>
			</div>
			
			<!-- raffles -->
			<div v-if="active_tab === 'raffles'">
				<div class="admin-container admin-container-raffles" v-for="(raffle, id) in raffles" :key="id">
					<img :src="raffle.nft_image" style="width: 100px;" />
					<div>
						<span>Type: {{ raffle.type }}</span><br/>
						<span>End: {{ raffle.end_date }}</span>
					</div>
					<div>
						<span>Expected winners: {{ raffle.winners }}</span><br/>
						<span>Winners drawn: {{ raffle.winners_drawn.length }}</span>
					</div>
					<div>
						<span>Available tickets: {{ raffle.tickets }}</span><br/>
						<span>Tickets sold: {{ raffle.tickets_sold }}</span>
					</div>
					<div class="button button-primary" style="height: 40px;" @click="active_tab = 'edit_raffle'; edit_raffle = raffle;" v-if="raffle.live === true">Edit</div>
				</div>
			</div>
			
			<!-- edit raffle -->
			<div v-if="active_tab === 'edit_raffle'" class="admin-container">
				<table>
					<template v-if="edit_raffle.type == 'nft'">
						<tr>
							<td>NFT name</td>
							<td><input type="text" v-model="edit_raffle.nft_name" /></td>
						</tr>
						<tr>
							<td>NFT image</td>
							<td><input type="text" v-model="edit_raffle.nft_image" /></td>
						</tr>
					</template>
					<template v-if="new_raffle.type == 'wl'">
						<tr>
							<td>Project name</td>
							<td><input type="text" v-model="edit_raffle.nft_name" /></td>
						</tr>
						<tr>
							<td>Project image</td>
							<td><input type="text" v-model="edit_raffle.nft_image" /></td>
						</tr>
					</template>
					<tr>
						<td><div class="admin-save-button button-secondary" @click="edit_raffle = null; active_tab = 'raffles'">Cancel</div></td>
						<td><div class="admin-save-button button-primary" @click="save_edit_raffle()">Save</div></td>
					</tr>
				</table>
			</div>
			
			<!-- param -->
			<div v-if="active_tab === 'param'" class="admin-container">
				<table>
					<tr>
						<td>Theme</td>
						<td>
							<select v-model="project.theme">
								<option value="dark">Dark</option>
								<option value="light">Light</option>
								<option value="custom">Custom</option>
							</select>
						</td>
					</tr>
					<tr>
						<td>Logo url</td>
						<td><input type="text" v-model="project.logo" /></td>
					</tr>
					<tr>
						<td>Token name</td>
						<td><input type="text" v-model="project.token_name" /></td>
					</tr>
					<tr>
						<td>Token address</td>
						<td><input type="text" v-model="project.token_address" /></td>
					</tr>
					<tr>
						<td>Token Associated Account</td>
						<td><input type="text" v-model="project.token_associated_account" /></td>
					</tr>
					<tr>
						<td></td>
						<td><div class="admin-save-button button-primary" @click="save_config()">Save</div></td>
					</tr>
				</table>
			</div>
			
			<!-- custom theme -->
			<div v-if="active_tab === 'custom_theme'" class="admin-container">
				<div class="button-primary" @click="default_values('dark')" style="padding: 5px; cursor: pointer;">Default values DARK</div><br/>
				<div class="button-primary" @click="default_values('light')" style="padding: 5px; cursor: pointer;">Default values LIGHT</div>
				<br/>
				<table>
					
					<tr>
						<td>Background</td>
						<td><input type="color" v-model="project.css_background" /></td>
					</tr>
					<tr>
						<td>Background header</td>
						<td><input type="color" v-model="project.css_background_primary" /></td>
					</tr>
					<tr>
						<td>Font color NFT name</td>
						<td><input type="color" v-model="project.css_color_nft_name" /></td>
					</tr>
					<tr>
						<td>Font color count down</td>
						<td><input type="color" v-model="project.css_color_countdown" /></td>
					</tr>
					<tr>
						<td>Box shadow header</td>
						<td><input type="color" v-model="project.css_box_shadow_header" /></td>
					</tr>
					
					<tr>
						<td colspan="2"><h2>Buttons</h2></td>
					</tr>
					<tr>
						<td>Background button primary</td>
						<td><input type="color" v-model="project.css_background_button_primary" /></td>
					</tr>
					<tr>
						<td>Font color button primary</td>
						<td><input type="color" v-model="project.css_color_button_primary" /></td>
					</tr>
					<tr>
						<td>Background button primary</td>
						<td><input type="color" v-model="project.css_background_button_primary_hover" /></td>
					</tr>
					<tr>
						<td>Font color button primary hover</td>
						<td><input type="color" v-model="project.css_color_button_primary_hover" /></td>
					</tr>
					<tr>
						<td>Background button secondary</td>
						<td><input type="color" v-model="project.css_background_button_secondary" /></td>
					</tr>
					<tr>
						<td>Font color button secondary</td>
						<td><input type="color" v-model="project.css_color_button_secondary" /></td>
					</tr>
					<tr>
						<td>Background button secondary</td>
						<td><input type="color" v-model="project.css_background_button_secondary_hover" /></td>
					</tr>
					<tr>
						<td>Font color button secondary hover</td>
						<td><input type="color" v-model="project.css_color_button_secondary_hover" /></td>
					</tr>
					
					<tr>
						<td>Background button back to raffle</td>
						<td><input type="color" v-model="project.css_background_button_back_to_raffle" /></td>
					</tr>
					
					
					<tr>
						<td></td>
						<td><div class="admin-save-button button-primary" @click="save_config()">Save</div></td>
					</tr>
				</table>
			</div>
			
			<!-- new raffle -->
			<div v-if="active_tab === 'new_raffle'" class="admin-container">
				<table>
					<tr>
						<td>Type</td>
						<td>
							<select v-model="new_raffle.type">
								<option value="nft">NFT</option>
								<option value="wl">WL</option>
							</select>
						</td>
					</tr>
					<template v-if="new_raffle.type == 'nft'">
						<tr>
							<td>NFT address</td>
							<td><input type="text" v-model="new_raffle.nft_address"  @change="get_image_link()" /></td>
						</tr>
						<tr>
							<td>NFT name</td>
							<td><input type="text" v-model="new_raffle.nft_name" /></td>
						</tr>
						<tr>
							<td>NFT image</td>
							<td><input type="text" v-model="new_raffle.nft_image" /></td>
						</tr>
						<tr>
							<td></td>
							<td><img :src="new_raffle.nft_image" v-if="new_raffle.nft_image != ''" style="width: 200px;"/></td>
						</tr>
					</template>
					<template v-if="new_raffle.type == 'wl'">
						<tr>
							<td>Project name</td>
							<td><input type="text" v-model="new_raffle.nft_name" /></td>
						</tr>
						<tr>
							<td>Project image</td>
							<td><input type="text" v-model="new_raffle.nft_image" /></td>
						</tr>
					</template>
					<tr>
						<td>Ticket price</td>
						<td><input type="text" v-model="new_raffle.price" /></td>
					</tr>
					<tr>
						<td>Available tickets</td>
						<td><input type="text" v-model="new_raffle.tickets" /></td>
					</tr>
					<tr v-if="new_raffle.type == 'wl'">
						<td>Winners</td>
						<td><input type="text" v-model="new_raffle.winners" /></td>
					</tr>
					<tr>
						<td>End date</td>
						<td><input type="datetime-local" v-model="new_raffle.end_date" /></td>
					</tr>
					<tr>
						<td></td>
						<td><div class="admin-save-button button-primary" @click="save_new_raffle()">Save</div></td>
					</tr>
				</table>
			</div>
			


		</div>
		<div v-if="$root.wallet_address === null">
			<div id="admin-connect-wallet">
				<div class="button button-primary" @click="$root.$emit('openPopup')">Please connect wallet to get access to this page</div>
			</div>
		</div>
		
		
	</div>
</template>


<script>

import axios from 'axios';

import $ from 'jquery';
import {create_new_raffle,create_new_raffle_wl} from '@/libs/rustProgram';
import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {confirmTransaction, getNftMetadata} from '@/libs/solanaConnection';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;',
	}
}


export default {
	name: 'Admin',
	components: {},

	data: function () {

		return {
			
			display_modal: true,
			edit_raffle: null,
			active_tab: 'raffles',
			project: {},
			raffles: [],
			new_raffle: {
				
				type: 'nft',
				winners: 1,
				tickets: 5000,
				price: 10,
				nft_image: '',
				nft_name: '',
				// nft_address: '5g8TtBLD5UQpvB8QHSUSGU7GgKXkPUnQKaRDHGcNAZto',
				nft_address: '',
				end_date: '',
			},
		}
	},
	
	computed: {
		
		
	},

	mounted: function(){
		
		
	},
	
	created: function(){
		
		var $this = this;
		
		this.$root.$on('projectSelected', function() {
		
			$this.project = $this.$root.project;
			
			$this.get_raffles(); 
		});
		
		this.$root.get_project_ready = true;
	},

	methods: {
		
		default_values: function(theme) {
		
			if(theme == 'dark') {
				
				this.project.css_background = '#1f1f1f';
				this.project.css_background_primary = '#292929';
				this.project.css_border_color = '#292929';
				this.project.css_color_nft_name = '#999999';
				this.project.css_color_countdown = '#bdbcbc';
				this.project.css_box_shadow_header = '#141414';
				
				// primary
				this.project.css_background_button_primary = '#5f4794';
				this.project.css_color_button_primary = '#ffffff';
				this.project.css_background_button_primary_hover = '#4f397f';
				this.project.css_color_button_primary_hover = '#ffffff';
				
				// secondary
				this.project.css_background_button_secondary = '#3a3a3a';
				this.project.css_color_button_secondary = '#838383';
				this.project.css_background_button_secondary_hover = '#4e4d4d';
				this.project.css_color_button_secondary_hover = '#afaeae';
				
				// misc
				this.project.css_background_button_back_to_raffle = '#000000';
				
				
			}
			else {
				
				this.project.css_background = '#ebebeb';
				this.project.css_background_primary = '#ffffff';
				this.project.css_border_color = '#ededed';
				this.project.css_color_nft_name = '#575757';
				
				this.project.css_color_countdown = '#5f4794';
				this.project.css_box_shadow_header = '#d1d1d1';
				
				// primary
				this.project.css_background_button_primary = '#5f4794';
				this.project.css_color_button_primary = '#ffffff';
				this.project.css_background_button_primary_hover = '#4f397f';
				this.project.css_color_button_primary_hover = '#ffffff';
				
				// secondary
				this.project.css_background_button_secondary = '#d1d1d1';
				this.project.css_color_button_secondary = '#939292';
				this.project.css_background_button_secondary_hover = '#c6c6c6';
				this.project.css_color_button_secondary_hover = '#747272';
				
				// misc
				this.project.css_background_button_back_to_raffle = '#000000';
			}
		},
		
		get_image_link: async function() {
		
			
			if(this.new_raffle.nft_address == '') {
				
				this.new_raffle.nft_image = '';
				this.new_raffle.nft_name = '';
				return;
			}
			
			var metadata = await getNftMetadata(this.new_raffle.nft_address);
			
			var uri = metadata.data.uri.replaceAll('\u0000', '');
			
			var json = await axios.get(uri);
			
			this.new_raffle.nft_image = json.data.image;
			this.new_raffle.nft_name = json.data.name;
			
		},
		get_raffles: function() {
			
			var component = this;
			var project = this.project;
			
			axios.get('https://raffle-back.effe2.xyz/raffle/get-raffles/'+project.id).then(function (result) {
				
				component.raffles = result.data.raffles;
			});
		},
		
		save_config: function() {
		
			var project = this.project;
			var $this = this;
			
			axios.post('https://raffle-back.effe2.xyz/raffle/save-project', {project}).then(function (result) {
				
				$this.$root.modal = {title: 'Perfect !', text: 'Configuration saved successfully'};
				$this.$root.$emit('openModal');
			});
		},
		
		save_new_raffle: async function() {
		
			var project = this.$root.project
			var new_raffle = this.new_raffle;
			
			var newDate = new Date(new_raffle.end_date);
			
			var signature;
			var escrow;
			var data_transaction;
			var data_transaction_to_be_saved;
			var $this = this;
			var confirmed_transaction;
			
			this.$root.$emit('openLoader');
			
			if(new_raffle.type == 'nft') {
			
				try {
					
					[signature, escrow] = await create_new_raffle(getSolanaObject(), getWalletAddress(), new_raffle.nft_address, new_raffle.price, new_raffle.tickets, newDate.getTime(), project.decimals, project.id);
				
					confirmed_transaction = await confirmTransaction(signature);
					
					// save the info of the raffle anyway
					
					data_transaction_to_be_saved = this.new_raffle;
					data_transaction_to_be_saved.escrow = escrow;
					data_transaction_to_be_saved.creator_address = getWalletAddress();
					
					data_transaction = {
						
						data: data_transaction_to_be_saved,
						signature: signature,
						type: 'create_raffle',
						project_id: project.id,
					};
					
					await axios.post('https://raffle-back.effe2.xyz/raffle/save-transaction', data_transaction);
					
					if(confirmed_transaction === true) {
					
						$this.$root.modal = {title: 'Perfect !', text: "Raffle will be live in the next minutes !"};
						$this.$root.$emit('openModal');
						
						await axios.get('https://raffle-back.effe2.xyz/raffle/check-transactions');
					}
					else {
						
						$this.$root.modal = {title: 'Oops !', text: "Transaction did not confirm in time, please check the transaction "+signature+" and try again if necessary"};
						$this.$root.$emit('openModal');
					}
				}
				catch(e) {
					
					console.log(e);
					$this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
					$this.$root.$emit('openModal');
				}
			}
			else {
				
				try {
					
					[signature, escrow] = await create_new_raffle_wl(getSolanaObject(), getWalletAddress(), new_raffle.price, new_raffle.tickets, newDate.getTime(), project.decimals, project.id);
				
					confirmed_transaction = await confirmTransaction(signature);
					
					// save the info of the raffle anyway
					
					data_transaction_to_be_saved = this.new_raffle;
					data_transaction_to_be_saved.escrow = escrow;
					data_transaction_to_be_saved.creator_address = getWalletAddress();
					
					data_transaction = {
						
						data: data_transaction_to_be_saved,
						signature: signature,
						type: 'create_raffle',
						project_id: project.id,
					};
					
					await axios.post('https://raffle-back.effe2.xyz/raffle/save-transaction', data_transaction);
					
					if(confirmed_transaction === true) {
					
						$this.$root.modal = {title: 'Perfect !', text: "Raffle will be live in the next minutes !"};
						$this.$root.$emit('openModal');
						
						await axios.get('https://raffle-back.effe2.xyz/raffle/check-transactions');
					}
					else {
						
						$this.$root.modal = {title: 'Oops !', text: "Transaction did not confirm in time, please check the transaction "+signature+" and try again if necessary"};
						$this.$root.$emit('openModal');
					}
				}
				catch(e) {
					
					console.log(e);
					$this.$root.modal = {title: 'Oops !', text: "Something went wrong, please try again"};
					$this.$root.$emit('openModal');
				}
				
			}
			
			this.$root.$emit('closeLoader');
		},
		
		save_edit_raffle: async function() {
		
			var project = this.$root.project
			var raffle = this.edit_raffle;
			
			var signature;
			
			this.$root.$emit('openLoader');
			
			var data = {
				
				nft_image: raffle.nft_image,
				nft_name: raffle.nft_name,
				raffle_id: raffle.id,
				project_id: project.id,
			};
			
			await axios.post('https://raffle-back.effe2.xyz/raffle/edit-raffle', data);
			
			await this.get_raffles();
			
			this.$root.modal = {title: 'Perfect !', text: "Raffle edited successfully !"};
			this.$root.$emit('openModal');
			
			this.edit_raffle = null;
			this.active_tab = 'raffles';
			
			this.$root.$emit('closeLoader');
		},
		
		
		
		
	},
	watch:{}
}
</script>



